import React from 'react';
import { Helmet } from 'react-helmet';

function Terms_and_Conditions_Seo() {
  return (
    <div>
      <Helmet>
                <title>Terms and Conditions | Pay Quik</title>
                <meta name="keywords" content="" />
                <meta name="description" content="" />
                <meta property="og:title" content="Terms and Conditions | Pay Quik" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
                <meta property="og:url" content="https://pay-quik.co.uk/terms_and_conditions" />
                <meta name="twitter:title" content="Terms and Conditions | Pay Quik" />
                <meta name="twitter:description" content="" />
                <meta name="twitter:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
            </Helmet>
    </div>
  )
}

export default Terms_and_Conditions_Seo

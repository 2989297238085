import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import countryPageMapping from '../CountryMap.json'; // Assuming CountryMap.json is in the same 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiConfig from '../ApiConfig';
function KenyaCalculator() {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('10'); // Default selected country ID
    const [selectedBranchId, setSelectedBranchId] = useState('2');
    const [selectedCountryDetails, setSelectedCountryDetails] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [amount, setAmount] = useState('1.00');
    const [rate, setRate] = useState('');
    const [currencyCode, setCurrencyCode] = useState('');
    const [fees, setFees] = useState('');
    const [calculatedAmount, setCalculatedAmount] = useState('');
    const [collectionTypes, setCollectionTypes] = useState([]);
    const [selectedCollectionType, setSelectedCollectionType] = useState('');
    const [selectedCollectionTypeName, setSelectedCollectionTypeName] = useState('');
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [payementtypeId, setpaymenttypeId] = useState('');
    const [countryId, setcountryId] = useState('');
    const [BasecurrencyData, setBasecurencydata] = useState([]);
    const [BasecurrencyId, setbasecurrencyId] = useState('');
    const [selectBasecountryId, setselectBasecountryId] = useState('');
    const [deliveryTypes, setDeliveryTypes] = useState([]);
    const [selectedDeliveryType, setSelectedDeliveryType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const baseUrl = '../ApiConfig.js'
    const navigate = useNavigate();
    const location = useLocation();
    const amountTimeoutRef = useRef(null);

    const baseCountryFlags = {
        1: 'assets/img/flags/gbp.png',
        6: 'assets/img/flags/eur.png',
    };
    const [baseCountryFlag, setBaseCountryFlag] = useState(baseCountryFlags['1']);

    // Parse URL to set the selected country
    useEffect(() => {
        const pathParts = location.pathname.split('_');
        if (pathParts.length > 1) {
            const countryFromURL = pathParts[1];
            const matchedCountry = countries.find(country => country.countryName.toLowerCase() === countryFromURL.toLowerCase());
            if (matchedCountry) {
                setSelectedCountry(matchedCountry.countryID.toString());
                setSelectedCountryDetails(matchedCountry);
                setCurrencyCode(matchedCountry.countryCurrency);
            }
        }
    }, [location.pathname, countries]);

    // Fetch countries on component mount


    useEffect(() => {
        axios.post(`${ApiConfig.baseUrl}/checkrateslistcountry/basecurrencylist`, {
            clientID: '1',
            branchID: selectedBranchId,
        })
        .then((response) => {
            if (response?.data?.response && response?.data?.data) {
                setBasecurencydata(response.data.data);
                const defaultBaseCurrency = response.data.data[0];
                if (defaultBaseCurrency) {
                    setbasecurrencyId(defaultBaseCurrency?.baseCurrencyID.toString());
                    setcountryId(defaultBaseCurrency?.countryID.toString());
              
                 
                    
                }
            }
        })
        .catch((error) => console.error('Error fetching base currency:', error));
    }, [selectedBranchId]);

    // const basecr = BasecurrencyId;
    // alert(basecr)


    useEffect(() => {
        axios.post(`${ApiConfig.baseUrl}/checkrateslistcountry/checklistcountry`, {
            clientID: '1',
        })
        .then((response) => {
            if (response.data.response && response.data.data) {
                setCountries(response.data.data);
                const defaultCountryDetails = response.data.data.find(
                    (country) => country.countryID === parseInt(selectedCountry, 10)
                );
                setSelectedCountryDetails(defaultCountryDetails);
            }
        })
        .catch((error) => {
            console.error('Error fetching countries:', error);
        });
    }, [selectedCountry]);

    // Fetch base currency based on selected branch


    // Fetch currency list based on selected country and branch
    useEffect(() => {
        if (selectedCountry && selectedBranchId) {
            axios.post(`${ApiConfig.baseUrl}/currency/currencylist`, {
                countryID: selectedCountry,
                clientID: '1',
            })
            .then((response) => {
                if (response.data.response && response.data.data) {
                    setCurrencyList(response.data.data);
                    const firstCurrency = response.data.data[0];
                    if (firstCurrency) {
                        setCurrencyCode(firstCurrency.currencyCode);
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching currency list:', error);
            });
        }
    }, [selectedCountry, selectedBranchId]);

    // Fetch collection types
    useEffect(() => {
        axios.post(`${ApiConfig.baseUrl}/collection/getcollectiontype`, {
            clientID: '1',
            branchID: selectedBranchId,
            countryID: selectedCountry,
        })
        .then((response) => {
            if (response.data.response && response.data.data) {
                setCollectionTypes(response.data.data);
                const defaultCollectionType = response.data.data[0];
                if (defaultCollectionType) {
                    setSelectedCollectionType(defaultCollectionType.paymentCollectionTypeID.toString());
                    setSelectedCollectionTypeName(defaultCollectionType.typeName);
                }
            }
        })
        .catch((error) => {
            console.error('Error fetching collection types:', error);
        });
    }, [selectedCountry, selectedBranchId]);

    // Fetch delivery types
    useEffect(() => {
        axios.post(`${ApiConfig.baseUrl}/deliverytype/getdeliverytype`, {
            clientID: '1',
            branchID: selectedBranchId,
            countryID: selectedCountry,
        })
        .then((response) => {
            if (response.data.response && response.data.data) {
                setDeliveryTypes(response.data.data);
                const defaultDeliveryType = response.data.data[0];
                if (defaultDeliveryType) {
                    setSelectedDeliveryType(defaultDeliveryType.deliveryTypeID.toString());
                }
            }
        })
        .catch((error) => {
            console.error('Error fetching delivery types:', error);
        });
    }, [selectedCountry, selectedBranchId]);

    // Fetch payment types
    useEffect(() => {
        axios.post(`${ApiConfig.baseUrl}/paymenttype/getpaytypes`, {
            clientID: '1',
            branchID: selectedBranchId,
            customerID: "",
            baseCountryID: countryId,
            isApp: "0"
        })
        .then((response) => {
            if (response.data.response && response.data.data) {
                setPaymentTypes(response.data.data);
                const defaultPaymentType = response.data.data[0];
                if (defaultPaymentType) {
                    setpaymenttypeId(defaultPaymentType.payTypeID.toString());
                    setSelectedPaymentType(defaultPaymentType.payType);
                }
            }
        })
        .catch((error) => console.error('Error fetching payment types:', error));
    }, [selectedBranchId, countryId]);

    // Fetch rates and fees based on user inputs
    useEffect(() => {
        if (amount && selectedCountry && currencyCode) {
            if (amountTimeoutRef.current) {
                clearTimeout(amountTimeoutRef.current);
            }
            setIsLoading(true);
            amountTimeoutRef.current = setTimeout(() => {
                axios.post(`${ApiConfig.baseUrl}/checkrateslistcountry/checkrateslistcountry`, {
                    clientID: '1',
                    countryID: selectedCountry,
                    paymentTypeID: "2",
                    paymentDepositTypeID: selectedCollectionType,
                    deliveryTypeID: selectedDeliveryType,
                    transferAmount: amount,
                    currencyCode: currencyCode,
                    branchID: selectedBranchId,
                    // BaseCurrencyID: BasecurrencyId // Pass the BaseCurrencyID here
                    BaseCurrencyID: '0' // Pass the BaseCurrencyID here
                })
                .then((response) => {
                    if (response.data.response && response.data.data && response.data.data.length > 0) {
                        let foundRate = false;
                        response.data.data.forEach(rateData => {
                            const rateValue = rateData.rate;
                            const minAmount = rateData.minAmount;
                            const maxAmount = rateData.maxAmount;
                            if (rateValue !== 0 && amount >= minAmount && amount <= maxAmount) {
                                setRate(rateValue);
                                setFees(rateData.transferFeesGBP);
                                setCalculatedAmount((amount * rateValue).toFixed(2) || '');
                                foundRate = true;
                            }
                        });
                        if (!foundRate) {
                            toast.error("Rates And Fees Are Not Available For This Country");
                            setAmount('0');
                            setCalculatedAmount('0');
                        }
                    } else {
                        toast.error("Rates And Fees Are Not Available For This Country");
                        setAmount('0');
                        setCalculatedAmount('0');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching rates and fees:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }, 1000);
        }
    }, [amount, selectedCountry, currencyCode, selectedCollectionType, BasecurrencyId, payementtypeId, selectedDeliveryType, selectedBranchId]);

    const handleCountryChange = (event) => {
        const newCountryId = event.target.value;
        setSelectedCountry(newCountryId);
        const newCountryDetails = countries.find(country => country.countryID.toString() === newCountryId);
        setSelectedCountryDetails(newCountryDetails);
        if (newCountryDetails) {
            setCurrencyCode(newCountryDetails.countryCurrency);
            const pagePath = countryPageMapping[newCountryId];
            if (pagePath) {
                navigate(`/${pagePath}`);
            } else {
                navigate('/default-page');
            }
        }
    };

    const handleAmountChange = (event) => {
        let newAmount = event.target.value.replace(/[^\d.]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
        if (newAmount.length > 12) {
            newAmount = newAmount.substring(0, 12);
        }
        setAmount(newAmount);
        setCalculatedAmount((newAmount * rate).toFixed(2) || '');
    };

    const handleCalculatedAmountChange = (event) => {
        let newCalculatedAmount = event.target.value.replace(/[^\d]/g, ''); // Allow only digits
        if (newCalculatedAmount.length > 0) {
            newCalculatedAmount = newCalculatedAmount.replace(/^0+/, '');
        }
        setCalculatedAmount(newCalculatedAmount);
        if (rate) {
            setAmount((newCalculatedAmount / rate).toFixed(2) || '');
        }
    };

    const handleAmountFocus = (event) => {
        event.target.value = event.target.value.replace(/,/g, '');
    };

    const handleAmountBlur = (event) => {
        event.target.value = parseFloat(event.target.value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const handleCollectionTypeChange = (event) => {
        const selectedType = event.target.value;
        setSelectedCollectionType(selectedType);
        const selectedTypeName = collectionTypes.find(type => type.paymentCollectionTypeID.toString() === selectedType)?.typeName;
        setSelectedCollectionTypeName(selectedTypeName || '');
    };

    const handleDeliveryTypeChange = (event) => {
        event.preventDefault();
        const selectedDeliveryType = event.target.value;
        setSelectedDeliveryType(selectedDeliveryType);
    };

    const handlePayementTypeChange = (event) => {
        event.preventDefault();
        const selectedPaymentType = event.target.value;
        setpaymenttypeId(selectedPaymentType);
    };

    const handleContinue = (event) => {
        event.preventDefault();
        if (!selectedCollectionType || !selectedDeliveryType || !amount) {
            toast.error("Please Fill All The Details.");
            return;
        }
        toast.success('Amount Calculated Successfully!');
        window.open('https://pay-quik.co.uk/app/app-login.html', '_blank');
    };
    
    return (
        <>
            <div className="service-charge-wrap">
            <form className="charge-form">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="send_money">Sending to?</label>
                                <select className="myselect" value={selectedCountry} onChange={handleCountryChange}>
                                    {countries
                                        .filter(country => [10, 224, 7, 8, 29, 220, 13, 9, 84].includes(country.countryID))
                                        .map(country => (
                                            <option key={country.countryID} value={country.countryID}>
                                                {country.countryName}
                                            </option>
                                        ))}
                                </select>

                                <span className="inputgbp">
                                    <img src={selectedCountryDetails?.flag || selectedCountryDetails?.countryFlag} alt="Sender country flag" />
                                    <span>{currencyCode || selectedCountryDetails?.currencyCode}</span>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="send_money">When you send GBP</label>
                                <input
                                    type="text"
                                    id="Number1"
                                    name="send_money"
                                    value={amount}
                                    onChange={handleAmountChange}
                                    onFocus={handleAmountFocus}
                                    onBlur={handleAmountBlur}
                                    maxLength={12} // Limiting input to 12 characters
                                />
                                <span className="inputgbp">
                                    <img src="assets/images/flags/gbp.png" alt="GBP flag" />
                                    <span>GBP</span>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <ul className="amount-currency-info">
                                <li className="d-flex justify-content-between align-items-center">
                                    <div className="info-icon">
                                        <i className="ri-subtract-line" />
                                        <strong className="coll_name">{selectedCollectionTypeName}</strong>
                                    </div>
                                    <div className="info-right">
                                        <div className="dropdown amount-currency-select">
                                            <select
                                                value={selectedCollectionType}
                                                className="dropdown dropdown-toggle"
                                                onChange={handleCollectionTypeChange}
                                            >
                                                {Array.isArray(collectionTypes) && collectionTypes.length > 0 ? (
                                                    collectionTypes.map(type => (
                                                        <option key={type.paymentCollectionTypeID} value={type.paymentCollectionTypeID}>
                                                            {type.typeName}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="">Select Collection Type</option>
                                                )}
                                            </select>

                                            <select
                                                label="Payment Type"
                                                value={payementtypeId}
                                                onChange={handlePayementTypeChange}
                                                className="select-delivery d-none"
                                            >
                                                {paymentTypes.map((paymentTypes) => (
                                                    <option key={paymentTypes.paymentCollectionTypeID} value={paymentTypes.payTypeID}>
                                                        {paymentTypes?.payType}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                label="Delivery Type"
                                                value={selectedDeliveryType}
                                                onChange={handleDeliveryTypeChange}
                                                className="select-delivery d-none"
                                            >
                                                {deliveryTypes.map((deliveryTypes) => (
                                                    <option key={deliveryTypes.deliveryTypeID} value={deliveryTypes.deliveryTypeID}>
                                                        {deliveryTypes?.deliveryType}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li className="d-flex justify-content-between align-items-center">
                                    <div className="info-icon">
                                        <i className="ri-pause-line" />
                                        <strong id="txtTransferFee" className="txtTransferFee">{`GBP ${fees}`}</strong>
                                    </div>
                                    <div className="info-right">
                                        <span>Transfer Fee</span>
                                    </div>
                                </li>
                                <li className="d-flex justify-content-between align-items-center">
                                    <div className="info-icon">
                                        <i className="ri-close-fill" />
                                        <strong className="txtExchangeRates">{rate}</strong>
                                    </div>
                                    <div className="info-right">
                                        <span>Guaranteed Rate</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="received_money">Recipient gets</label>
                                <input
                                    type="text"
                                    id="Number2"
                                    name="received_money"
                                    value={calculatedAmount}
                                    onChange={handleCalculatedAmountChange}
                                    onFocus={handleAmountFocus}
                                    onBlur={handleAmountBlur}
                                    maxLength={12} // Limiting input to 12 characters
                                />
                                <span className="inputgbp">
                                    <img src={selectedCountryDetails?.flag || selectedCountryDetails?.countryFlag} alt="Recipient country flag" />
                                    <select>
                                        <option>{currencyCode}</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                        {/* <div className="col-lg-12 text-center mb-2">
                            <p>Rates last updated on 12/06/2024 04:42 PM</p>
                        </div> */}
                        <div className="col-lg-12 col-md-12 d-flex justify-content-center">
                            <button className="thm-btn" onClick={handleContinue}>
                                <span className="txt">Send Money</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer
                position="bottom-right" // Set the position to bottom-right
                autoClose={5000} // Set the auto-close duration (in milliseconds)
                hideProgressBar={false} // Show or hide the progress bar
                newestOnTop={false} // Display newest toast notifications on top
                closeOnClick // Close the toast when clicked
                rtl={false} // Right-to-left support
                pauseOnFocusLoss // Pause toast timer when the window loses focus
                draggable // Make toast notifications draggable
                pauseOnHover
                style={{ fontWeight: '500' }}
            />
        </>
        
    );
    }
    
    export default KenyaCalculator;
    
    
       

import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Preloader from '../../Components/Preloader';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import Calculator from '../../Components/Calculator';
import UgandaCalculator from '../../Components/CalculatorMultipages/UgandaCalculator';
import Home_Seo from '../../SEO/Home_Seo';

function Home() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 1-second delay
        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                // Your actual content when not loading
                <div>
                    <Home_Seo />
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="fix">
                        <Header />
                        {/* hero section start */}
                        <section className="hero-sec bg-green hero-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="hero-content">
                                            <span className="hero-tagline text-teal">Fast, Secure & Seamless</span>
                                            <h1 className="hero-title text-white">International Money Transfer </h1>
                                            <p className="text-white">Pay Quik aims to become a leading international digital money transfer business, utilizing modern technology to provide secure, low-cost, and fast online transfers.</p>
                                            <div className="hero-btn-area d-flex align-items-center">

                                                {/* <NavLink className="thm-btn" to="/">
                                    <span className="txt">Read More</span>
                                </NavLink>
                                <NavLink to="https://www.youtube.com/watch?v=UVAyIh5V4NY" className="play-btn"><i className="fas fa-play" /></NavLink>
                                <span className="play-intro">Play Intro</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="hero-images hero-style1">
                                            <div className="hero-feature-img feature-one br-style1">
                                                <UgandaCalculator />
                                                {/* <img className="br-style1" src="assets/images/hero/hero-img1.png" alt="hero-img" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* hero section end */}
                        {/* service category section start */}
                        <section className="service-cat-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                                        <div className="single-service">
                                            {/* service item 1 */}
                                            <div className="service-num"><h3>01</h3></div>
                                            <div className="service-content">
                                                <img className='mb-3' src="assets/img/icons/create-account.svg" />
                                                <NavLink to="#" className="service-title">Create an account</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="700ms" data-wow-duration="1500ms">
                                        <div className="single-service">
                                            {/* service item 2 */}
                                            <div className="service-num"><h3>02</h3></div>
                                            <div className="service-content">
                                                <img className='mb-3' src="assets/img/icons/setup-transfer.svg" />
                                                <NavLink to="#" className="service-title">Set up a transfer</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="900ms" data-wow-duration="1500ms">
                                        <div className="single-service">
                                            {/* service item 3 */}
                                            <div className="service-num"><h3>03</h3></div>
                                            <div className="service-content">
                                                <img className='mb-3' src="assets/img/icons/pay-secure.svg" />
                                                <NavLink to="#" className="service-title">Pay securely</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="1100ms" data-wow-duration="1500ms">
                                        <div className="single-service">
                                            {/* service item 4 */}
                                            <div className="service-num"><h3>04</h3></div>
                                            <div className="service-content">
                                                <img className='mb-3' src="assets/img/icons/thats-it.svg" />
                                                <NavLink to="#" className="service-title">That’s it!</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center" >
                                    <div class="col-lg-2 col-md-3 col-6" >
                                        <NavLink to="/money_transfer_to_kenya">
                                            <div class="flag-div d-flex align-items-center" >
                                                <div class="flag-mr" >
                                                    <img class="flag" src="assets/images/flags/kes.png" alt="Money Transfer to Kenya" />
                                                </div>
                                                <div class="currency-code" >
                                                    <h4>Kenya</h4>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <div class="col-lg-2 col-md-3 col-6" >
                                        <NavLink to="/money_transfer_to_uganda">
                                            <div class="flag-div d-flex align-items-center" >
                                                <div class="flag-mr" >
                                                    <img class="flag" src="assets/images/flags/ugx.png" alt="Money Transfer to Uganda" />
                                                </div>
                                                <div class="currency-code" >
                                                    <h4>Uganda</h4>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    {/* <div class="col-lg-2 col-md-3 col-6" >
                    <NavLink to="/money_transfer_to_somalia">
                      <div class="flag-div d-flex align-items-center" >
                        <div class="flag-mr" >
                          <img class="flag" src="assets/images/flags/sos.png" alt="Money Transfer to Somalia" />
                        </div>
                        <div class="currency-code" >
                          <h4>Somalia</h4>
                        </div>
                      </div>
                    </NavLink>
                  </div> */}
                                    <div class="col-lg-2 col-md-3 col-6" >
                                        <NavLink to="/money_transfer_to_ethiopia">
                                            <div class="flag-div d-flex align-items-center" >
                                                <div class="flag-mr" >
                                                    <img class="flag" src="assets/images/flags/etb.png" alt="Money Transfer to Ethiopia" />
                                                </div>
                                                <div class="currency-code" >
                                                    <h4>Ethiopia</h4>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    {/* <div class="col-lg-2 col-md-3 col-6" >
                    <NavLink to="/money_transfer_to_uae">
                      <div class="flag-div d-flex align-items-center" >
                        <div class="flag-mr" >
                          <img class="flag" src="assets/images/flags/aed.png" alt="Money Transfer to UAE" />
                        </div>
                        <div class="currency-code" >
                          <h4>UAE</h4>
                        </div>
                      </div>
                    </NavLink>
                  </div> */}
                                    {/* <div class="col-lg-2 col-md-3 col-6" >
                    <NavLink to="/money_transfer_to_turkey">
                      <div class="flag-div d-flex align-items-center" >
                        <div class="flag-mr" >
                          <img class="flag" src="assets/images/flags/try.png" alt="Money Transfer to Turkey" />
                        </div>
                        <div class="currency-code" >
                          <h4>Turkey</h4>
                        </div>
                      </div>
                    </NavLink>
                  </div> */}
                                    <div class="col-lg-2 col-md-3 col-6" >
                                        <NavLink to="/money_transfer_to_australia">
                                            <div class="flag-div d-flex align-items-center" >
                                                <div class="flag-mr" >
                                                    <img class="flag" src="assets/images/flags/aud.png" alt="Money Transfer to Australia" />
                                                </div>
                                                <div class="currency-code" >
                                                    <h4>Australia</h4>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <div class="col-lg-2 col-md-3 col-6" >
                                        <NavLink to="/money_transfer_to_usa">
                                            <div class="flag-div d-flex align-items-center" >
                                                <div class="flag-mr" >
                                                    <img class="flag" src="assets/images/flags/usd.png" alt="Money Transfer to USA" />
                                                </div>
                                                <div class="currency-code" >
                                                    <h4>USA</h4>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <div class="col-lg-2 col-md-3 col-6" >
                                        <NavLink to="/money_transfer_to_djibouti">
                                            <div class="flag-div d-flex align-items-center" >
                                                <div class="flag-mr" >
                                                    <img class="flag" src="assets/images/flags/djf.png" alt="Money Transfer to Djibouti" />
                                                </div>
                                                <div class="currency-code" >
                                                    <h4>Djibouti</h4>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* service category section end */}

                        {/* about section start */}
                        <section className="about-sec sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="sec-images">
                                            <div className="sec-img-one overflow-hidden wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
                                                <img src="assets/images/about/about01.png" alt="about-img" />
                                            </div>
                                            <div className="sec-img-two overflow-hidden wow fadeInRight" data-wow-delay="500ms" data-wow-duration="1500ms">
                                                <img src="assets/images/about/about02.png" alt="about-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 tab-col-gap wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                                        <div className="sec-content">
                                            <h6 className="sec-sub-title">About Us</h6>
                                            <h2 className="sec-title">Pay Quik</h2>
                                            <p className="sec-text">
                                                Pay Quik was started by a group of dedicated entrepreneurs with extensive money transfer experience. After spending over 20 years working at traditional money transfer companies we saw a lot of problems – to put it simply, the majority of money transfer companies have not moved with modern technology and consumer habits. We knew we could do better and so Pay Quik was born.
                                            </p>
                                            <div className="sec-btn">
                                                <NavLink className="thm-btn" to="/about"><span className="txt">Read More</span></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* about section end */}
                        {/* features section start */}
                        <section className="service-sec bg-light-greem sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="sec-content">
                                            <h6 className="sec-sub-title">Our features</h6>
                                            <h2 className="sec-title">Why choose Pay Quik</h2>
                                            <p className="sec-text">
                                                Pay Quik offers a reliable, digital money transfer service meeting the needs of our customers.
                                            </p>

                                        </div>
                                    </div>
                                    <div className="col-lg-12 tab-col-gap">
                                        <div className="service-lists service-lists-style1">
                                            <div className="single-service align-items-center wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                {/* single-service 1*/}
                                                <div className="single-service-icon">
                                                    <img src="assets/img/icons/quick-transfer.svg" />
                                                </div>
                                                <div className="single-service-content">
                                                    <h4>Quick Transfer</h4>
                                                    <p>Our advanced platform enables us to fulfil most transfers immediately.</p>
                                                </div>
                                            </div>
                                            <div className="single-service align-items-center wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                                {/* single-service 2*/}
                                                <div className="single-service-icon">
                                                    <img src="assets/img/icons/low-cost.svg" />
                                                </div>
                                                <div className="single-service-content">
                                                    <h4>Low Cost</h4>
                                                    <p>Fair and transparent pricing shown up front. Get the best exchange rates & low transfer fees.</p>
                                                </div>
                                            </div>
                                            <div className="single-service align-items-center wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                                {/* single-service 3*/}
                                                <div className="single-service-icon">
                                                    <img src="assets/img/icons/hassel-free.svg" />
                                                </div>
                                                <div className="single-service-content">
                                                    <h4>Hassle Free</h4>
                                                    <p>Send from anywhere and at anytime giving you complete flexibility.</p>
                                                </div>
                                            </div>
                                            <div className="single-service align-items-center wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                {/* single-service 4*/}
                                                <div className="single-service-icon">
                                                    <img src="assets/img/icons/safe-secure.svg" />
                                                </div>
                                                <div className="single-service-content">
                                                    <h4>Safe and Secure</h4>
                                                    <p>Advanced privacy and data encryption technology.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* features section end */}
                        {/* newslatter section start */}
                        <section className="newslatter-sec bg-green">
                            <div className="container">
                                <div className="row aximo_screenfix_left">
                                    <div className="col-lg-7 p-0">
                                        <div className="video-sec">
                                            <div className="video-sec-overlay d-flex align-items-center justify-content-center">
                                                {/*<NavLink to="#" className="video-btn"><i className="flaticon-play-button-arrowhead" /></NavLink> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 d-flex align-items-center">
                                        <div className="sec-content">
                                            <h2 className="sec-title">Send Money with Pay Quik</h2>
                                            <p className="sec-text">Ready to give Pay Quik a Try? It’s quick and easy to get started. Sign up today and get your 1st transfer Fee-Free!</p>
                                            <div className="Subscribe-form d-sm-flex">
                                                <div className="submit-btn">
                                                    <NavLink to="https://pay-quik.co.uk/remit/" target='_blank' className="thm-btn">
                                                        <span className="txt">Send Money Now</span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* newslatter section start */}
                        {/* business section start */}
                        <section className="service-sec bg-light-greem sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="sec-content">
                                            <h6 className="sec-sub-title">Our Payout Methods</h6>
                                            <h2 className="sec-title">Flexible Payout Options</h2>
                                            <p className="sec-text">
                                                We offer a convenient and low-cost alternative to traditional money transfer companies that use high street agents and charge you substantial fees to send your hard-owned money. Regardless of where you are or what time or day it is, you can send money from our website or phone app to your friends and family abroad.
                                            </p>
                                            <p className="sec-text">
                                                <strong>Our platform allows for you to choose how money is paid out</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 tab-col-gap">
                                        <div className="service-lists service-lists-style1">
                                            <div className="single-service align-items-center wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                {/* single-service 1*/}
                                                <div className="single-service-icon">
                                                    <img src="assets/img/icons/mobile-wallet.svg" />
                                                </div>
                                                <div className="single-service-content">
                                                    <h4>Mobile Wallet</h4>
                                                    <p>Instant transfers to your chosen mobile money wallet account.</p>
                                                </div>
                                            </div>
                                            <div className="single-service align-items-center wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                                {/* single-service 2*/}
                                                <div className="single-service-icon">
                                                    <img src="assets/img/icons/bank-transfer.svg" />
                                                </div>
                                                <div className="single-service-content">
                                                    <h4>Bank Transfer</h4>
                                                    <p>Direct to chosen bank account.</p>
                                                </div>
                                            </div>
                                            <div className="single-service align-items-center wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                                                {/* single-service 3*/}
                                                <div className="single-service-icon">
                                                    <img src="assets/img/icons/cash-payout.svg" />
                                                </div>
                                                <div className="single-service-content">
                                                    <h4>Cash Payouts</h4>
                                                    <p>Allowing for customers who prefer cash pickups.</p>
                                                </div>
                                            </div>
                                            <div className="single-service align-items-center wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                {/* single-service 4*/}
                                                <div className="single-service-icon">
                                                    <img src="assets/img/icons/airtime.svg" />
                                                </div>
                                                <div className="single-service-content">
                                                    <h4>Airtime</h4>
                                                    <p>Top-up the mobile accounts of your family/friends.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* business section start */}
                        {/* testimonial section start */}
                        <section className="testimonial-sec sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="offset-lg-3 col-lg-6">
                                        <div className="sec-content text-center mb-5">
                                            <h6 className="sec-sub-title">Clients Testimonials</h6>
                                            <h2 className="sec-title">What Our Customers Say</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <OwlCarousel
                                        className="testimonial-slider owl-carousel owl-theme"
                                        loop={true}
                                        margin={30}
                                        nav={false}
                                        dots={true}
                                        dotsEach={true}
                                        autoplay={true}
                                        autoplayTimeout={3000}
                                        responsive={{
                                            0: { items: 1 }, // 1 item for screen width 0px and up
                                            600: { items: 1 }, // 1 item for screen width 600px and up
                                            1000: { items: 2 } // 2 items for screen width 1000px and up
                                        }}
                                    >
                                        {/* Item 1 */}
                                        <div className="item">
                                            <div className="single-testimonial">
                                                <div className="feedback">
                                                    <div className="rating">
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                    </div>
                                                    <p className="comment">
                                                        “I am one of those people that has ‘an app for everything’. I used to send money back home to my parents using an agent near my workplace but I hated queueing up. I now send money and keep track of it all via the Pay Quik app - saves me time and money.”
                                                    </p>
                                                </div>
                                                <div className="customar-info">
                                                    <div className="customar-title">
                                                        <h5>Adamu</h5>
                                                    </div>
                                                </div>
                                                <div className="com-logo">
                                                    <i className="flaticon-ux" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Item 2 */}
                                        <div className="item">
                                            <div className="single-testimonial">
                                                <div className="feedback">
                                                    <div className="rating">
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                    </div>
                                                    <p className="comment">
                                                        “I’ve been supporting my younger brother’s education for years - sending him money to pay for school fees etc. I am a price conscious person so always like to compare services - I always find Pay Quik to be reliable and good value for money.”
                                                    </p>
                                                </div>
                                                <div className="customar-info">
                                                    <div className="customar-title">
                                                        <h5>Angela</h5>
                                                    </div>
                                                </div>
                                                <div className="com-logo">
                                                    <i className="flaticon-ux" />
                                                </div>
                                            </div>
                                        </div>

                                    </OwlCarousel>
                                </div>
                            </div>
                        </section>
                        {/* testimonial section end */}

                        {/* app section start */}
                        <section className="about-sec about-sec2 sec-ptb bg-light-greem">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="sec-images sec-img-style">
                                            <div className="sec-img-one wow fadeInLeft" data-wow-delay="0.5s" data-wow-duration="1500ms">
                                                <img src="assets/images/about/about04.png" alt="about-img" />
                                            </div>
                                            <div className="sec-img-info2 d-flex flex-column justify-content-center align-items-center wow fadeInRight" data-wow-delay="0.5s" data-wow-duration="1500ms">
                                                <div className="">
                                                    <NavLink to="/" target='_blank'>
                                                        <img className="mb-3" src="assets/images/google-app.png" alt="about-img" />
                                                    </NavLink>
                                                    <NavLink to="/" target='_blank'>
                                                        <img src="assets/images/ios-app.png" alt="about-img" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 tab-col-gap wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                                        <div className="sec-content">
                                            <h6 className="sec-sub-title">Download our app</h6>
                                            <h2 className="sec-title">Save time with the Pay Quik app</h2>
                                            <p className="sec-text">
                                                Begin your money transfer journey with Pay Quik app. Download our app from Play Store or App Store and enjoy benefits that comes along:
                                            </p>
                                            <ul className="sec-list-item">
                                                <li><i className="fa-solid fa-square-check" /> Easy sign up.</li>
                                                <li><i className="fa-solid fa-square-check" /> Save time with Repeat Transfer.</li>
                                                <li><i className="fa-solid fa-square-check" /> Track your payments in real time.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* app section start */}
                        <Footer />
                    </div>
                </div>
            )}

        </div>

    )
}

export default Home
